function countFont1(){
  var originRootFontSize = parseFloat(window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size'));
  var realSize =  document.documentElement.clientWidth/ 10; // 这里的7.5是我自己，你看着调
  
  console.log("originRootFontSize："+originRootFontSize + "realSize:" + realSize); //  系统字体放大缩小，originRootFontSize 和 realSize相等的话就可以了
  
  if (originRootFontSize === realSize) {
      return;
  }
  // 计算原字体和放大后字体的比例
  var scaleFactor = realSize / originRootFontSize ;

  // 取html元素的字体大小
  // 注意，这个大小也经过缩放了
  // 所以下方计算的时候 *scaledFontSize是原来的html字体大小
  // 再次 *scaledFontSize才是我们要设置的大小
  if (scaleFactor !== 1) {
      document.getElementsByTagName('html')[0].style.cssText = 'font-size:' + originRootFontSize * scaleFactor * scaleFactor  + 'px !important';
      
  }
}

$(window).resize(function() {
  // setTimeout(() =>{ // 我这里是设置延时覆盖原先全局 html 的font-size
      countFont1();
  // },0)
});

(function(){
//  setTimeout(() =>{  // 我这里是设置延时覆盖原先全局 html 的font-size
  countFont1();
//  },0)
})();