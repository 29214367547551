import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import nodata from '@/views/err/noData.vue'
Vue.component('nodata',nodata)
import goPage from '@/components/page/index.vue'
Vue.component('goPage',goPage)
Vue.config.productionTip = false
import 'vant/lib/index.css';
import 'amfe-flexible'
// import Vconsole from 'vconsole';
// let vConsole = new Vconsole();
// export default vConsole
import {
  Tabbar,
  TabbarItem,
  Form,
  Field,
  Button,
  NavBar,
  Toast,
  Tabs,
  Tab,
  List,
  Lazyload,
  CellGroup,
  Cell,
  Icon,
  Grid,
  GridItem,
  Popup,
  Row,
  Col,
  Tag,
  Image,
  PullRefresh,
  ActionSheet,
  Loading,
  Search,
  Swipe, 
  SwipeItem
} from 'vant'
Vue.use(SwipeItem)
Vue.use(Swipe)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(NavBar)
Vue.use(Toast)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(List)
Vue.use(Lazyload)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(Icon)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Popup)
Vue.use(Row)
Vue.use(Col)
Vue.use(Tag)
Vue.use(Image)
Vue.use(PullRefresh)
Vue.use(ActionSheet)
Vue.use(Loading)
Vue.use(Search)
import "@/utils/rem.js" //rem


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
