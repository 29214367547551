import Vue from 'vue'
import Vuex from 'vuex'
import {getMenulist,setMenulists} from '@/utils/storage.js'
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate"

var ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "南开旗舰店"
});

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      // key: "",
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  state: {
    // 记录路由
    pathUrl:0,
    // 判断手机系统
    tyPhone:'',
    // 浏览器头部标题
    topTittle:'',
    // 传递内容
    menulist:'',
    advertisingId:'',
    // 内容类型
    type:'',
    // 栏目类目
    menuType:'',
    // 按钮样式
    columnStyle:'',
    // 详情的id
    detailsId:'',
    // 传递tab
    tabType:'',
    tablistType:'',
    tabId:'',
    // 判断列表显示形式
    listId:'',
    // 列表获取数据
    listData:'',
    // 判断是否在app中
    jxbApp:'',
    // 判断用户名
    homeName:''
  },
  mutations: {
    setPathUrl(state,pathUrl){
      state.pathUrl = pathUrl
    },
    setTyPhone(state,tyPhone){
      state.tyPhone = tyPhone
    },
    setHomeName(state,homeName){
      state.homeName = homeName
    },
    setJxbApp(state,jxbApp){
      state.jxbApp = jxbApp
    },
    setTopTittle(state,topTittle){
      state.topTittle = topTittle
    },
    setListData(state,listData){
      state.listData = listData
      // setMenulists('listData',listData)
    },
    setListId(state,listId){
      state.listId = listId
      // setMenulists('listId',listId)
    },
    settabId(state,tabId){
      state.tabId = tabId
      // setMenulists('tabId',tabId)
    },
    settabTtpe(state,tabType){
      state.tabType = tabType
      // setMenulists('tabType',tabType)
    },
    settablistType(state,tablistType){
      state.tablistType = tablistType
      // setMenulists('tablistType',tablistType)
    },
    setMenulist(state,menulist){
      state.menulist = menulist
      // setMenulists('menulist',menulist)
    },
    setAdvertisingId(state,advertisingId){
      state.advertisingId = advertisingId
      // setMenulists('advertisingId',advertisingId)
    },
    setStyleType(state,type){
      state.type = type
      // setMenulists('type',type)
    },
    setMenuType(state,menuType){
      state.menuType = menuType
      // setMenulists('menuType',menuType)
    },
    setColumnStyle(state,columnStyle){
      state.columnStyle = columnStyle
      // setMenulists('columnStyle',columnStyle)
    },
    setDetailsId(state,detailsId){
      state.detailsId = detailsId
      // setMenulists('detailsId',detailsId)
    }
  },
  actions: {
  }
})
