<template>
  <div class="noData">
    <img src="../../assets/zanwu.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.noData{}
img{
  width: 7.822rem;
  height: 7.822rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}
</style>